import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const ClickFrameworkPage = () => (
  <div>
    <SEO 
        title="1 Click Framework Installer" 
        description="By employing the PHP 1 Click Framework Installer integrated in the Sheephostingbay Web 
        Control Panel, it’s easy to install a brand–new PHP framework for your new project."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="py-12 mt-5 pb-0">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                <p className="section-title"> <span className="block text-indigo-600 xl:inline">Web Control Panel’s</span> 1 Click Framework Installer <span role="img" aria-label="emoji">✨ </span></p>
                <p className="section-after-title">A PHP 1 Click Framework Installer that is genuinely well designed</p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                      <a 
                      href="https://demo.hepsia.com/installer/frameworks/?auto_login=true" 
                      rel="noreferrer" 
                      target="_blank" 
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10">
                      View Demo
                      </a>
                  </div>
              </div>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <p className="m-0">
                        <img src="https://sheephostingbay.duoservers.com/template2/img/framework-installer-banner.webp" alt="Sheephostingbay Web control panel"/>
                    </p>
                  </div>
              </div>
              
            </div>
        </div>
        
    </div>

    <div className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                 <p className="section-after-title">
                 <span role="img" aria-label="emoji">🦄</span> By employing the PHP 1 Click Framework Installer integrated in the Sheephostingbay Web 
                 Control Panel, it’s easy to install a brand–new PHP framework for your new project. No need to try to find the latest version of a 
                 framework and furthermore spend your time setting it up. Everything you should do is just pick the place of your PHP framework 
                 and afterwards click the Install button.
                </p>
              
            </div>
        </div>
        
    </div>

    <div className="py-32 server-bg">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="overflow-hidden bg-white shadow-xl dark:bg-gray-800 sm:rounded-lg">
              <div>
                  <div className="grid grid-cols-1 bg-gray-200 bg-opacity-25 dark:bg-gray-800 md:grid-cols-2">
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/a-simple-app-installation.webp" className="w-60" alt="A variety of frameworks accessible" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">A variety of frameworks accessible</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Start your web venture by employing a framework<br/><br/>
                                    From our Sheephostingbay Web Control Panel, it will be easier to set up a well–known open–source PHP framework. 
                                    We’ve prepared a number of the most widespread frameworks that are now available on the web – CakePHP, 
                                    Laravel, CodeIgniter, Zend, Symfony, and YII. You can integrate just about any framework on any web 
                                    site that’s hosted with your hosting account.
                                    <br/><br/>
                                    The PHP 1 Click Framework Installer tool can be obtained with all of the web hosting services that come with our 
                                    Web Control Panel – website hosting, VPS packages,dedicated servers hosting packages, and semi-dedicated 
                                    hosting packages.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">1–click PHP framework installment</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Your PHP framework is merely a mouse click away<br/><br/>
                                    The PHP 1 Click Framework Installer was designed to work on the same basic principle like our App Installer – 
                                    using minimal effort needed from you and Sheephostingbay’s system performing all the hard work instead of 
                                    you. PHP frameworks are set up with a few mouse clicks with no configuration demanded on your part. All 
                                    you must do is pick the spot of your respective PHP framework. That’s all.
                                    <br/><br/>
                                    We have a record of the running PHP frameworks so you can un–install every single PHP framework which you 
                                    don’t require with only a mouse click.
                                    </div>
                                </div>
                                <img src="https://sheephostingbay.duoservers.com/template2/img/framework-installer.webp" className="w-60" alt="1–click PHP framework installment" />
                            </div>
                        </div>
                      
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/1-click-backup.webp" className="w-60" alt="1–click backup" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">1–click backup</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    The fastest method to back up a framework<br/><br/>
                                    Every decent programmer recognizes that it is essential to have a backup of one’s work to count on in the event 
                                    of a problem. That is why, we integrated a 1–click backup tool to the PHP 1 Click Framework Installer. It will 
                                    help you to back up your current framework and all of the modifications you have made, with just a click of 
                                    the mouse.
                                    <br/><br/>
                                    There isn’t a backup limit. If you have free disk space inside your account, you could make as many backups as 
                                    you wish.
                                    </div>
                                </div>
                            </div>
                        </div>   

                  </div>
              </div>
          </div>
      </div>
    </div>

    <Subscribe/>
    <Footer/>
  </div>
)

export default ClickFrameworkPage
